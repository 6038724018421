
body {
  margin: 0px;
  padding: 0px;
  background-color: var(--body_background);
  color: var(--body_color);
}
.App {
  text-align: center;
}
