:root {
  --body_background: white;
  --body_color: black;
  --nav_background: #acacac;
  --footer_background: #acacac;
  --record_background: #eaeaea;
  --record_border: #d5d5d5;
  --inner_record_border: rgb(17, 17, 17);
  --record_category_header: #acacac;
}

.dark-mode {
  --body_background: #282c34;
  --body_color: white;
  --nav_background: #181b20;
  --footer_background: #181b20;
  --record_background: hsl(0, 0%, 35%);
  --record_border: hsl(0, 0%, 41%);
  --inner_record_border: #181b20;
  --record_category_header: rgb(66, 64, 64);

}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


