.footersection {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 10rem;
    background-color: var(--footer_background);
    color: var(--body_color);
}

.top-footer {
    flex-direction: row;
    height: 100px;
}

.socialmedia-links {
    display: flex;
    flex-direction: row;
    height: 60px;
    padding: .5rem 1rem;
}
.donate-section {
    display: flex;
    max-width: 50%;
    margin-left: auto;
    }

@media only screen and (max-width: 720px) { 
    .App-logo {
        width: 60px;
        height: 60px;
        margin: 10px;
    } 
    .donate-section {
        width: 50%;
        margin-right: 10px;
    }
}
@media only screen and (min-width: 721px) { 
    .App-logo {
        width: 70px;
        height: 70px;
        margin: 10px; 
    }
    .donate-section {
        width: 50%
    }
}