.Record_List {
    background-color: var(--record_background);
    border-color: var(--record_border);
    border-width: 1pxs;
    border-style: solid;
    border-radius: 7px;
    width: 96%;
    margin: 2%;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    padding: 2px;
}
.RecordHeader {
    flex-direction: row;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    background-color: var(--record_category_header);

}
.RecordListing {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.col_listing {
    width: 16.64%;
    display: inline-block;
    border-style: solid;
    padding: 3%;
    border-color: var(--inner_record_border);
}

@media only screen and (max-width: 720px) { 
    .col_listing {
        font-size: 11px;
        padding-left: 2px;
        border-width: .5px;
        font-weight: 500;
    } 
}